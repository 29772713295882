<template>
  <div class="m-8">
    <!--    breadcrumb-->

    <vs-card>
      <div class="mb-4 flex items-center justify-between">
        <div class="vx-breadcrumb md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <router-link :to="{ name: 'center-admin-newsletters'}">Newsletters</router-link>
              <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"/>
                  <polyline points="6 17 11 12 6 7"/>
                </svg>
              </span>
            </span>
            </li>
            <li class="inline-flex items-end">
              <a href="javascript:void(0)" class>Edit newsletters</a>
              <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"/>
                  <polyline points="6 17 11 12 6 7"/>
                </svg>
              </span>
            </span>
            </li>
            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>{{ title }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="p-6">
        <h2 class="w-full mb-5">{{ date }}</h2>
        <p class="w-full mb-5" v-if="newsletterData">
          <strong>Author: </strong>
          {{ newsletterData.authorDetails ? newsletterData.authorDetails[0].fullName : '' | capitalize }}
        </p>
        <h2 class="font-bold text-black">{{ titleText | capitalize }}</h2>
        <!--        introduction-->
        <div v-if="editIntroduction === false" class="flex">
          <p class="w-full mb-5" v-html="newsletterData.introduction"></p>
          <div class="ml-5">
            <vs-button class="vs-button-secondary" @click="editIntroduction = true">Edit</vs-button>
          </div>
        </div>
        <div v-if="editIntroduction === true" class="w-full mt-5 mb-5">
          <div class="input-wrap w-full mb-5">
            <label class="ml-2">Add introduction</label>
            <quillEditor :pages="introduction"></quillEditor>
            <span class="text-danger text-sm">{{ errors.first('introduction') }}</span>
          </div>
          <div class="w-full">
            <vs-button @click="editNewsletterIntroduction">Save update</vs-button>
            <vs-button class="ml-5" color="danger" @click="cancelEdit('introduction')">Cancel</vs-button>
          </div>
        </div>
        <!--        photos-->
        <div class="mt-8 mb-base photo-wrapper">
          <ul class="photos-list flex">
            <li v-for="(image, index) in media" :key="index" class="inline-block">
              <a
                href="javascript::void(0)"
                @click.prevent="openGallery(index)"
              >
              <img v-lazy="image.src || image.thumb" class="h-32 object-contain"/>
              <feather-icon icon="XIcon" class="m-0 cursor-pointer" @click="removeImage(index)"/>
              </a>
            </li>
          </ul>
        </div>
        <div class="w-full" align="right">
          <input
            type="file"
            class="hidden"
            name="file"
            multiple
            ref="uploadImgInput"
            @change="uploadImage($event.target.files)"
            accept="image/*"
          />
          <vs-button
            class="mb-5 vs-button-secondary"
            @click="$refs.uploadImgInput.click()"
            type="border"
          >Upload photo
          </vs-button>
        </div>
        <!--        activities-->
        <div class="mt-8">
          <div align="right">
            <vs-button class="vs-button-secondary" @click="selectRooms">Add Activity</vs-button>
          </div>
          <ul v-if="activities.length > 0">
            <li v-if="activities.length > 0" v-for="(activity, aKey) in activities" :key="activity._id">
              <vs-row class="w-1/2">
                <vs-col class="w-1/2">
                  <h2 class="w-full mb-5 font-bold text-black">{{ activity.name }}</h2>
                </vs-col>
                <vs-col class="w-1/2 flex">
                  <vs-button class="vs-button-secondary ml-auto" @click="selectActivity(activity)">Edit</vs-button>
                  <vs-button class="vs-button-secondary ml-4"
                             @click="deleteNewsletterActivity(activity._id)">Delete
                  </vs-button>
                </vs-col>
              </vs-row>
              <vs-row class="w-1/2">
                <vs-col class="w-1/3 text-center">
                <strong>Core Skills</strong>
                  <img
                    v-if="activity.coreSkills.icon !==''"
                    :src="activity.coreSkills.icon"
                    class="h-16 object-contain block mx-auto mt-5"
                  />
                  <strong>{{ activity.coreSkills ? activity.coreSkills.name : '' }}</strong>
                </vs-col>
                <vs-col class="w-1/3 text-center">
                <strong>Ways of Learning</strong>
                  <img
                    v-if="activity.waysOfLearnings.icon"
                    :src="activity.waysOfLearnings.icon"
                    class="h-16 object-contain block mx-auto mt-5"
                  />

                  <strong>{{ activity.waysOfLearnings ? activity.waysOfLearnings.name : '' }}</strong>
                </vs-col>
                <vs-col class="w-1/3 text-center">
                 <strong>STREAM</strong>
                  <img
                    v-if="activity.streams.icon"
                    :src="activity.streams.icon"
                    class="h-16 object-contain block mx-auto mt-5"
                  />
                  <strong>{{ activity.streams ? activity.streams.name : '' }}</strong>
                </vs-col>
              </vs-row>
              <vs-row class="w-full mt-10 mb-5 flex">
                <p class=" text-black w-full">{{ activity.purposeStatement }}</p>
              </vs-row>
              <vs-row class="w-full mt-10 mb-5 flex">
                <div class="mt-8 mb-base photo-wrapper">
                  <ul class="photos-list flex-row">
                    <li v-for="(photo, key) in activity.photos" :key="key+0.6" class="inline-block mb-2">
                      <img v-lazy="photo.photo" class="h-32 object-cover"/>
                      <feather-icon icon="XIcon" class="m-0 cursor-pointer"
                                    @click="deleteActivityPhoto(activity._id, photo._id, aKey, key)"/>
                    </li>
                  </ul>
                </div>
                <input
                  type="file"
                  class="hidden"
                  name="file"
                  :ref="'uploadImgInputNew'+aKey"
                  @change="uploadActivityImage($event.target.files, activity._id, aKey)"
                  accept="image/*"
                />
                <vs-button
                  class="mb-5 vs-button-secondary"
                  @click="uploadButtonClick(aKey)"
                >Upload photo
                </vs-button>
              </vs-row>

            </li>
          </ul>
          <span v-else>
             <p class="w-full mb-5">No Activity Selected</p>
          </span>
        </div>

        <!--        exist text-->
        <div v-if="editExistText === false">
          <div align="right">
            <vs-button class="vs-button-secondary" @click="editExistText = true">Edit</vs-button>
          </div>
          <p class="w-full mb-5" v-html="newsletterData.existText"></p>
        </div>
        <div v-if="editExistText === true" class="w-full mt-5 mb-5">
          <div class="input-wrap w-full mb-5">
            <label class="ml-2">Add exit</label>
            <quillEditor :pages="existText"></quillEditor>
            <span class="text-danger text-sm">{{ errors.first('existText') }}</span>
          </div>
          <div class="w-full">
            <vs-button @click="editNewsletterExistText">Save update</vs-button>
            <vs-button class="ml-5" color="danger" @click="cancelEdit('existText')">Cancel</vs-button>
          </div>
        </div>
      </div>

      <div class="w-full my-10 px-5" align="right">
        <vs-button @click="saveNewsletter">Save draft</vs-button>
      </div>
    </vs-card>

    <!--    select rooms popup-->
    <vs-popup class="activityPopUp" title :active.sync="selectRoomPopUp">
      <vs-card class="rounded-none shadow-none w-full">
        <h2 class="mb-5 font-semibold">Select Rooms</h2>
        <div class="w-full mb-5">
          <label class="mb-2">Select Learning Centre</label>
          <v-select
            :options="centreOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="centreFilter"
          />
        </div>
        <div class="w-full mb-5">
          <label class="mb-2">Select Rooms</label>
          <v-select
            multiple
            :options="roomOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="roomFilter"
          />
        </div>
        <div>
          <vs-button :disabled="!isFormValid" @click="editActivity">Add Activity</vs-button>
        </div>
      </vs-card>
    </vs-popup>

    <!--    edit activity popup-->
    <vs-popup class="activityPopUp ph-popup-md" title :active.sync="editActivityPopUp">
       <vs-card class="rounded-none shadow-none">
        <!-- <h2 class="mb-5 font-semibold">Edit Activity</h2> -->
        <div>
        <div  style="position:sticky; top:0;background:white; z-index:1000;width:auto;">
           <vs-button class="m-3" align="right" @click="saveActivity">
            Add Activity</vs-button>
         </div>
         <div class="flex datepicker-wrap">
          <span class="btn btn-outline-primary"  @click="previousWeek"
            ><i class="feather icon-chevron-left"></i
          ></span>
          <span class="reportrange-text">
            <date-picker
              v-model="week"
              type="week"
              placeholder="Select week"
              prefix-class="xmx"
              ref="picker"
              @change="pickerChange"
              :lang="lang"
              :clearable="false"
              :show-week-number="false"
            >
              <template v-slot:input="{ emit }">{{ dateRange }}</template>
            </date-picker>
          </span>
          <span class="btn btn-outline-primary" @click="nextWeek"
            ><i class="feather icon-chevron-right" ></i
          ></span>
        </div>
        <div id="data-list-thumb-view" class="data-list-container vs-table vs-table--tbody-table">
          <table class="vs-table vs-table--tbody-table" width="100%">
            <thead class="vs-table--thead" style="position: sticky; top: 63px;">
            <tr>
              <th>Contributor</th>
              <th>Activity</th>
              <th>Core Skill</th>
              <th>Ways of Learning</th>
              <th>STREAM</th>
              <th>Select</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-if="activitiesData.length > 0"
              v-for="activity in activitiesData"
              :key="activity._id"
              class="tr-values vs-table--tr tr-table-state-null"
            >
              <td class="td vs-table--td">{{ activity.contributor[0].fullName }}</td>
              <td class="td vs-table--td">
                <p class="font-bold mb-1">{{ activity.name }}</p>
                <p>{{ activity.description }}</p>
              </td>
              <td class="td vs-table--td">
                <img
                  v-if="activity.coreSkill[0].icon !==''"
                  :src="activity.coreSkill[0].icon"
                  width="75px"
                  height="75px"
                />
                <br/>
                {{ activity.coreSkill ? activity.coreSkill[0].name : '' }}
              </td>
              <td class="td vs-table--td">
                <img
                  v-if="activity.waysOfLearning[0].icon"
                  :src="activity.waysOfLearning[0].icon"
                  width="75px"
                  height="75px"
                />
                <br/>
                {{ activity.waysOfLearning ? activity.waysOfLearning[0].name : '' }}
              </td>
              <td class="td vs-table--td">
                <img
                  v-if="activity.streams[0].icon"
                  :src="activity.streams[0].icon"
                  width="75px"
                  height="75px"
                />
                <br/>
                {{ activity.streams ? activity.streams[0].name : '' }}
              </td>
              <td class="td vs-table--td">
                <label class="form-checkbox">
                  <input type="checkbox" :value="activity._id" v-model="selectedActivities"/>
                  <i class="form-icon"></i>
                </label>
              </td>
            </tr>
            <tr v-if="activitiesData.length <= 0">
              <td colspan="6">No record found.</td>
            </tr>
            </tbody>
          </table>
        </div>
        </div>
        <!-- <vs-row class="flex mt-6">
          <vs-button align="right" @click="saveActivity">Add Activity</vs-button>
        </vs-row> -->
      </vs-card>
    </vs-popup>

    <!-- Individual Activity Edit-->
    <vs-popup class="holamundo add-follow-popup" title :active.sync="individualActivityEditPopUp" sty>
      <h2>Edit Activity</h2>
      <vx-card class="add-activity-card">
        <form>
          <!-- Only allow purpose of statement to be edited -->
          <!-- <h4>Core Skill</h4>
          <ul class="centerx flex-wrap">
            <li
              v-for="(index, key) in coreSkillsList"
              :key="key"
              style="display: inline-flex; margin-right: 20px; flex-wrap: wrap;"
            >
              <input
                type="radio"
                name="coreSkillId"
                v-model="coreSkills"
                :value="index._id"
                v-validate="'required'"
              />
              <div class="radio-wrap">
                <div>
                  <img
                    :src="index.icon ? index.icon : $defaultImage"
                    :alt="index.name"
                    height="40px"
                    width="40px"
                  />
                  <span>{{ index.name }}</span>
                </div>
              </div>
            </li>
          </ul>
          <span
            class="text-danger text-sm"
            v-show="errors.has('coreSkillId')"
          >{{ errors.first('coreSkillId') }}</span>
          <br/>
          <h4>Ways Of Learning</h4>

          <ul class="centerx flex-wrap">
            <li
              v-for="(index, key) in waysOfLearningsList"
              :key="key"
              style="display: inline-flex;  margin-right: 20px; flex-wrap: wrap;"
            >
              <input
                type="radio"
                name="waysOfLearningId"
                v-model="waysOfLearnings"
                :value="index._id"
                v-validate="'required'"
              />
              <div class="radio-wrap">
                <div>
                  <img
                    :src="index.icon ? index.icon : $defaultImage"
                    :alt="index.name"
                    height="40px"
                    width="40px"
                  />
                  <span>{{ index.name }}</span>
                </div>
              </div>
            </li>
          </ul>
          <span
            class="text-danger text-sm"
            v-show="errors.has('waysOfLearnings')"
          >{{ errors.first('waysOfLearnings') }}</span>
          <br/>
          <h4>STREAM</h4>

          <ul class="centerx flex-wrap">
            <li
              style="display: inline-flex;  margin-right: 20px; flex-wrap: wrap;"
              v-for="(index, key) in streamList"
              :key="key"
            >
              <input
                type="radio"
                name="streamId"
                v-model="stream"
                :value="index._id"
                v-validate="'required'"
              />
              <div class="radio-wrap">
                <div>
                  <img
                    :src="index.icon ? index.icon : $defaultImage"
                    :alt="index.name"
                    height="40px"
                    width="40px"
                  />
                  <span>{{ index.name }}</span>
                </div>
              </div>
            </li>
          </ul>
          <span
            class="text-danger text-sm"
            v-show="errors.has('stream')"
          >{{ errors.first('stream') }}</span>
          <br/> -->
          <!-- <div class="mb-2">
            <div class="w-full mb-3">
              <vs-input :class="name ? 'hasValue':''"
                        :label="'Name'"
                        v-model="name"
                        name="name"
                        label-placeholder="Name"
                        v-validate="'required'">
              </vs-input>

              <span
                class="text-danger text-sm"
                v-show="errors.has('name')"
              >{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="mb-2">
            <div class="w-full">
              <vs-textarea
                :class="description ? 'hasValue':''"
                :label="'Description'"
                v-model="description"
                name="description"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('description')"
              >{{ errors.first('description') }}</span>
            </div>
          </div> -->
          <div class="mb-2">
            <div class="w-full">
              <h2>Play with Purpose. (Your Intentionality)</h2>
              <vs-textarea
              :class="purposeOfStatement ? 'hasValue':''"
                label="Purpose Statement"
                v-model="purposeOfStatement"
                style="font-size: 16px; line-height: 23px; border: 1px solid #d8d8d8;"
              />
            </div>
          </div>

          <vs-button
            type="filled"
            @click.prevent="updateActivity"
            class="mt-5 block ml-auto btn-blue-border"
          >Update
          </vs-button>
        </form>
      </vx-card>
    </vs-popup>
    <LightBox
        ref="lightbox"
        :media="media"
        :show-light-box="false"
        :show-caption="false"
        :show-thumbs="false"
        @onImageChanged="onImageChanged"
      />
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import _ from "lodash";
  import Swal from "sweetalert2/dist/sweetalert2";
  import "sweetalert2/src/sweetalert2.scss";
  import vSelect from "vue-select";
  import quillEditor from '../../../Editor';
import DatePicker from "vue2-datepicker";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";


export default {
  components: {
    "v-select": vSelect,
    quillEditor,
    DatePicker,
    LightBox
  },
  data() {
    return {
        title: "",
        dataId: "",
        newsletterData: {},
        date: "",
        titleText: "",
        media: [],
        photos: [],
        activityMedia: [],
        activities: [],
        editIntroduction: false,
        editExistText: false,
        introduction: {content: null},
        existText: {content: null},
        editActivityPopUp: false,
        selectedActivities: [],
        activitiesTitle: [],
        activitiesData: [],
        centreOptions: [],
        centreFilter: {label: "All", value: "all"},
        roomOptions: [],
        roomFilter: {label: "All", value: "all"},
        selectRoomPopUp: false,
        selectedActivity: {},
        individualActivityEditPopUp: false,
        coreSkillsList: [],
        coreSkills: '',
        waysOfLearningsList: [],
        waysOfLearnings: '',
        streamList: [],
        stream: '',
        name: '',
        description: '',
      purposeOfStatement: '',
      currentImageIndex: 0,
        lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
        dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek()
      };
    },
    watch: {
      centreFilter(obj) {
        this.roomFilter = "";
        this.roomOptions = obj.roomsData;
      },
    },
    computed: {
      isFormValid() {
        return (
          !this.errors.any() &&
          this.centreFilter.value != "all" &&
          this.roomFilter.value != "all"
        );
      },
    },
    methods: {
      ...mapActions("coreSkill", [
        "fetchCoreSkills",
      ]),
      ...mapActions("stream", [
        "fetchStreams",
      ]),
      ...mapActions("waysOfLearning", [
        "fetchWaysOfLearning",
      ]),
      ...mapActions("newsletter", [
        "fetchNewsletterByIdCenterAdmin",
        "uploadImageToNewsletterCenterAdmin",
        "updateNewsletterCenterAdmin",
        "getActivitiesListCenterAdmin",
        "getRoomListCenterAdmin",
        "updateNewsletterActivity",
        "deleteNewsletterActivityPhoto",
        "uploadImageToNewsletterActivity",
        "removeNewsletterActivity"
      ]),
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
        this.currentImageIndex = index;
      },
      onImageChanged(index) {
        this.currentImageIndex = index;
      },
      pickerChange(value) {
      let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);
      this.startDate = sDate;
      this.endDate = eDate;

      this.dateRange = sDate + " - " + eDate;

      this.addActivity();
    },
    previousWeek() {
      let currentDate = this.startDate;

      let d = new Date(currentDate);

      let a = d.setDate(d.getDate() - 7);

      this.startDate = this.startOfWeek(a);
      this.endDate = this.endOfWeek(a);
      this.dateRange = this.startDate + " - " + this.endDate;

      this.addActivity();
    },
    nextWeek() {
      let currentDate = this.startDate;

      let d = new Date(currentDate);

      let a = d.setDate(d.getDate() + 7);

      this.startDate = this.startOfWeek(a);
      this.endDate = this.endOfWeek(a);
      this.dateRange = this.startDate + " - " + this.endDate;

      this.addActivity();
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;
      return this.moment(date.setDate(diff)).format("MMMM DD YYYY");
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);

      return this.moment(date.setDate(lastDate)).format("MMMM DD YYYY");
    },
      moment(date) {
        return moment(date);
      },
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: color,
        });
      },
      async getNewsletterDetails(id) {
        await this.fetchNewsletterByIdCenterAdmin(id).then(async (res) => {
          this.newsletterData = await res.data.data;
          let str = this.newsletterData.title.split(" ");
          this.date = str[0];
          str = str.slice(1);
          this.titleText = str.join(" ");
          this.media = await this.newsletterData.photos.map((item) => {
            return {
              thumb: item.photo,
              src: item.photo,
              srcset: item.photo,
            };
          });
          this.photos = this.newsletterData.photos;
          this.activities = (this.newsletterData.activitiesList.length && ("name" in this.newsletterData.activitiesList[0])) ? this.newsletterData.activitiesList : [];
          this.introduction.content = this.newsletterData.introduction;
          this.existText.content = this.newsletterData.existText;
        });
      },
      async uploadImage(file) {
        if (this.photos.length < 5) {
          this.$vs.loading();
          if (file.length > 0) {
            let obj = new FormData();
            for (let i = 0; i < file.length; i++) {
              obj.append("file", file[i]);

            }
            let data = {
              obj: obj,
              config: {
                header: {
                  "Content-Type": "image/png",
                },
              },
            };
            this.uploadImageToNewsletterCenterAdmin(data)
              .then(async (res) => {
                let location = res.data.data;

                if (this.photos.length > 0) {
                  this.photos = this.photos.concat(location);
                } else {

                  this.photos = location;
                }
                this.media = await this.photos.map((item) => {
                  return {
                    thumb: item.photo,
                    src: item.photo,
                    srcset: item.photo,
                  };
                });
                const info = {
                  id: this.newsletterData._id,
                  data: {
                    photos: this.photos,
                  },
                };
                this.updateNewsletterCenterAdmin(info).then((res) => {
                  this.$vs.loading.close();
                  this.showMessage(
                    "Success",
                    "Newsletter photo uploaded successfully.",
                    "success"
                  );
                  const id = res.data.data._id;
                  this.getNewsletterDetails(id);
                });
              })
              .catch((err) => {
                this.$vs.loading.close();
              });
          }
        } else {
          this.$vs.loading.close();
          this.showMessage("Failed", "You can only upload five photos", "danger");
        }
      },
      removeImage(imageId) {
        let self = this;
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove it!",
        }).then(async (result) => {
          if (result.value) {
            self.media.splice(imageId, 1);
            self.photos.splice(imageId, 1);
            const info = {
              id: this.newsletterData._id,
              data: {
                photos: self.photos,
              },
            };

            this.updateNewsletterCenterAdmin(info).then((res) => {
              this.$vs.loading.close();
              this.showMessage(
                "Success",
                "Newsletter photo removed successfully.",
                "success"
              );
              this.getNewsletterDetails(result._id);
            });
          }
        });
      },
      async addActivity() {
      if (this.centreFilter.value != "all" && this.roomFilter.value != "all") {
        let a = await this.roomFilter.map(item => item.value);

        let info = {
          centreId: this.centreFilter._id,
          rooms: a,
          startDate: this.startDate,
          endDate: this.endDate
        };
        this.$vs.loading();
        await this.getActivitiesListCenterAdmin(info)
          .then(res => {
            this.activitiesData = res.data.data;
            this.addActivityPopUp = true;
          })
          .catch(err => {
            this.$vs.loading.close();
          });
        this.$vs.loading.close();
      } else {
        this.showMessage(
          "Failed",
          "Learning Centre and rooms should be selected should be selected first",
          "danger"
        );
      }
    },
      editNewsletterIntroduction() {
        const info = {
          id: this.newsletterData._id,
          data: {
            introduction: this.introduction.content,
          },
        };
        this.$vs.loading();
        this.updateNewsletterCenterAdmin(info).then((res) => {
          this.editIntroduction = false;
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Newsletter introduction edited successfully.",
            "success"
          );
          const id = res.data.data._id;
          this.getNewsletterDetails(id);
        });
      },
      editNewsletterExistText() {
        const info = {
          id: this.newsletterData._id,
          data: {
            existText: this.existText.content,
          },
        };
        this.$vs.loading();
        this.updateNewsletterCenterAdmin(info).then((res) => {
          this.editExistText = false;
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Newsletter exit edited successfully.",
            "success"
          );
          const id = res.data.data._id;
          this.getNewsletterDetails(id);
        });
      },
      cancelEdit(val) {
        if (val === "introduction") {
          this.introduction.content = this.newsletterData.introduction;
          this.editIntroduction = false;
        }
        if (val === "existText") {
          this.existText.content = this.newsletterData.existText;
          this.editExistText = false;
        }
      },
      async selectRooms() {
        this.$vs.loading();
        await this.getRoomListCenterAdmin().then(async (res) => {
          this.centreOptions = this.$store.state.newsletter.centreOptions;
        });
        let a;
        a = this.newsletterData.roomDetails.map((item) => {
          return {
            label: item.name,
            value: item._id
          }
        });
        this.centreFilter = {
          label: this.newsletterData.centreDetails.name,
          value: this.newsletterData.centreDetails._id
        };
        let selectedCentre = _.find(this.centreOptions, this.centreFilter);
        this.centreFilter = selectedCentre;
        this.roomFilter = await a;
        this.roomOptions = selectedCentre.roomsData;
        this.$vs.loading.close();
        this.selectRoomPopUp = true;
      },
      async editActivity() {
        this.selectRoomPopUp = false;
        if (this.centreFilter.value != "all" && this.roomFilter.value != "all") {
          let a = await this.roomFilter.map((item) => item.value);
          let info = {
            centreId: this.centreFilter._id,
            rooms: a,
            startDate: this.startDate,
          endDate: this.endDate
          };
          this.newsletterData.rooms = a;
          this.newsletterData.learningCenterId = this.centreFilter._id;
          await this.getActivitiesListCenterAdmin(info)
            .then((res) => {
              this.activitiesData = res.data.data;
              this.editActivityPopUp = true;

            })
            .catch((err) => {
              this.$vs.loading.close();
            });
          this.$vs.loading.close();
        } else {
          console.log('c');
          this.showMessage(
            "Failed",
            "Learning Centre and rooms should be selected should be selected first",
            "danger"
          );
        }
      },
      async saveActivity() {
        this.editActivityPopUp = false;
        this.$vs.loading();
        const info = {
          id: this.newsletterData._id,
          data: {
            activities: this.selectedActivities,
            learningCenterId: this.newsletterData.learningCenterId,
            rooms: this.newsletterData.rooms,
          },
        };
        this.updateNewsletterCenterAdmin(info).then((res) => {
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Newsletter activities edited successfully.",
            "success"
          );
          const id = res.data.data._id;
          this.getNewsletterDetails(id);
        });
      },
      saveNewsletter() {
        const data = {
          date: this.newsletterData.date,
          title: this.newsletterData.title,
          introduction: this.newsletterData.introduction,
          photos: this.newsletterData.photos,
          existText: this.newsletterData.existText,
          hasPublished: this.newsletterData.hasPublished,
          createdBy: this.newsletterData.createdBy,
          learningCenterId: this.newsletterData.learningCenterId,
          rooms: this.newsletterData.rooms,
        };
        this.$vs.loading();
        const info = {
          id: this.newsletterData._id,
          data: data,
        };
        this.updateNewsletterCenterAdmin(info).then((res) => {
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Newsletter draft saved successfully.",
            "success"
          );
        });
        this.$router.push({name: "center-admin-newsletters"});
      },
      selectActivity(activity) {
        this.selectedActivity = activity;
        this.name = activity.name;
        this.description = activity.description;
        this.purposeOfStatement = activity.purposeStatement;
        this.coreSkills = activity.coreSkills._id;
        this.waysOfLearnings = activity.waysOfLearnings._id;
        this.stream = activity.streams._id;
        this.individualActivityEditPopUp = true;
      },
      async deleteNewsletterActivity(activityId) {
        let self = this;
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove it!",
        }).then(async (result) => {
          if (result.value) {
            self.$vs.loading();
            const data = {
              newsletterId: this.dataId,
              activityId: activityId
            };
            self.removeNewsletterActivity(data).then((res) => {
              self.$vs.loading.close();
              self.getNewsletterDetails(self.dataId);
            }).catch((err) => {
              self.$vs.loading.close();

              console.log(err);
            })
          }
        });
      },
      async updateActivity() {
        this.$vs.loading();

        const obj = {
          _id: this.selectedActivity._id,
          name: this.name,
          description: this.description,
          coreSkills: this.coreSkills,
          waysOfLearnings: this.waysOfLearnings,
          stream: this.stream,
          purposeStatement: this.purposeOfStatement
        };
        const data = {
          newsletterId: this.dataId,
          data: obj,
        };
        await this.updateNewsletterActivity(data).then((res) => {
          this.$vs.loading.close();
          this.getNewsletterDetails(this.dataId);
          this.selectedActivity = {}
          this.individualActivityEditPopUp = false;


        }).catch(err => {
          this.$vs.loading.close();
          console.log(err)
        });

      },
      async deleteActivityPhoto(activityId, photoId, activityKey, photoKey) {
        this.$vs.loading();
        const data = {
          activityId: activityId,
          newsletterId: this.dataId,
          photoId: photoId
        };
        await this.deleteNewsletterActivityPhoto(data).then((res) => {
          this.$vs.loading.close();

          this.activities[activityKey].photos.splice(photoKey, 1);
        }).catch(error => {
          console.log(error)
          this.$vs.loading.close();

        });

      },
      uploadButtonClick(aKey) {
        this.$refs['uploadImgInputNew' + aKey][0].click();
      },
      async uploadActivityImage(file, activityId, activityKey) {
        this.$vs.loading();
        let imageFile = file[0];
        if (file.length > 0) {
          let obj = new FormData();
          obj.append("file", imageFile);
          obj.append("newsletterId", this.dataId);
          obj.append("activityId", activityId);
          let data = {
            obj: obj,
            config: {
              header: {
                "Content-Type": "image/png",
              },
            },
          };
          await this.uploadImageToNewsletterActivity(data).then(async (res) => {
            this.$vs.loading.close();
            let location = res.data.data;
            let a = {
              photo: location,
            };
            this.activities[activityKey].photos.push(a);
          })
            .catch((err) => {
              this.$vs.loading.close();
            });
        }
      },
    },
    created() {
      this.title = this.$route.query.title;
      this.dataId = this.$route.params.id;
      this.getNewsletterDetails(this.dataId);
      this.fetchCoreSkills()
        .then((res) => {
          this.coreSkillsList = this.$store.state.coreSkill.coreSkills;
        })
        .catch(err => {
          console.error(err)
        });

      this.fetchStreams().then((res) => {
        this.streamList = this.$store.state.stream.streams;
      })

      this.fetchWaysOfLearning().then((res) => {
        this.waysOfLearningsList = this.$store.state.waysOfLearning.waysOfLearning;

      });
    },

  };
</script>

<style lang="scss">
  .activityPopup {
    .vs-popup--title {
      visibility: hidden;
    }

    .vs-card {
      min-width: 90%;

      p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 5px;
      }

      .con-select {
        .vs-select--input {
          padding: 20px;
          border: 1px solid #838383;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .photo-wrapper {
    .vs-button {
      display: inline-block;
      margin-top: 20px;
    }
  }
</style>
